import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/app/src/components/providers/react-query/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppSessionProvider"] */ "/app/src/components/providers/session/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/providers/theme/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["customThemeColors","skyBlue"] */ "/app/src/utils/theme.tsx");
