"use client";

import { globalThemeConfig, otcThemeConfig } from "@/utils/theme";
import { AntdRegistry } from "@ant-design/nextjs-registry";
import { ThemeProvider } from "admiral";
import { usePathname } from "next/navigation";
import React from "react";

const AntConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const params = usePathname();
  const isOtc = params.includes("otc");

  return (
    <ThemeProvider theme={isOtc ? otcThemeConfig : globalThemeConfig}>
      <AntdRegistry>{children}</AntdRegistry>
    </ThemeProvider>
  );
};

export default AntConfigProvider;
