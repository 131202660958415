"use client";
import {
  themeColors as admiralThemeColors,
  iconActionTableStyle,
} from "admiral";
import type { TThemeConfig } from "admiral/context";
import type { RowProps, SpaceProps } from "antd";
import type { Breakpoint } from "antd/lib";
import Link from "next/link";
import type React from "react";

export const customThemeColors = {
  ...admiralThemeColors,
  white: "#FFF",
  primary: "#F8C301",
  secondary: "#001D66",
  warning: "#FFFBE6",
  link: "#002C8C",
  danger: "#A8071A",
  success: "#52c41a",
  error: "#ff4d4f",
  stepInactive: "#b8c1cc",
  darkYellow: "#FAAD14",
  skyBlue: "1890ff",
  pink: "#FFADD2",
  approval: {
    return: "#D4B106",
    revise: "#F8C301",
    approve: "#001D66",
    reject: "#A8071A",
  },
  status: {
    base: {
      backgroundColor: "#d3d3d3",
      color: "#000",
      borderColor: "#d3d3d3",
    },
    return: {
      backgroundColor: "#FFF7E6",
      color: "#FA8C16",
      borderColor: "#FFD591",
    },
    sapPark: {
      backgroundColor: "#ECFFF4",
      color: "#095245",
      borderColor: "#33AD97",
    },
    cancel: {
      backgroundColor: "#FFF7E6",
      color: "#EB2F96",
      borderColor: "#EB8FE2",
    },
    failed: {
      backgroundColor: "#FFF2E8",
      color: "#FA541C",
      borderColor: "#FFBB96",
    },
    waitingPark: {
      backgroundColor: "#F9F0FF",
      color: "#722ED1",
      borderColor: "#D3ADF7",
    },
    rejected: {
      backgroundColor: "#FFF1F0",
      color: "#F5222D",
      borderColor: "#FFA39E",
    },
    pending: {
      backgroundColor: "#FEFFE6",
      color: "#FADB14",
      borderColor: "#FADB14",
    },
    waitingApprovalByBudgetOwner: {
      backgroundColor: "#F3FAFF",
      color: "#3F3939",
      borderColor: "#A6B2BD",
    },
    waitingEarmarkFund: {
      backgroundColor: "#E6F4FF",
      color: "#1677FF",
      borderColor: "#91CAFF",
    },
    finalReject: {
      backgroundColor: "#FFF5F4",
      color: "#66110D",
      borderColor: "#A85A56",
    },
    receivedDocs: {
      backgroundColor: "#FFFBE6",
      color: "#FAAD14",
      borderColor: "#FFE58F",
    },
    otcWaitingApproval: {
      backgroundColor: "#FFF7E6",
      color: "#FA8C16",
      borderColor: "#FFD591",
    },
    otcRevise: {
      backgroundColor: "#FFF2E8",
      color: "#FA541C",
      borderColor: "#FFBB96",
    },
    otcDraft: {
      backgroundColor: "#FEFFE6",
      color: "#FADB14",
      borderColor: "#FADB14",
    },
    otcDone: {
      backgroundColor: "#ECFFF4",
      color: "#095245",
      borderColor: "#33AD97",
    },
    sapPosted: {
      color: "#722ED1",
    },
    waitingPost: {
      color: "#33AD97",
    },
    waitingApprovalByFo: {
      color: "#EEC07D",
    },
  },
  font: {
    black: "#000000",
    lightgray: "#00000073",
    gray: "#000000A6",
    errPage: "#E2E8F9",
    darkBlue: "#253B78",
    yellow: "#FFE58F",
    lightBlue: "#ACBADF",
  },
  icon: {
    yellow: "#FFCC29",
    lightBlue: "#E6F4FFCC",
    blue: "#253D7F",
    red: "#CF1322",
    gold: "#D4B106",
    black: "#000000E0",
  },
  button: {
    red: "#CF1322",
    blue: "#003EB3",
    gray: "#FFFFFFB2",
    darkBlue: "#00247D",
  },
  opex: {
    approval: {
      approve: "#237804",
      reject: "#CF1322",
      revise: "#D48806",
      submit: "#001D66",
      cancel: "#CF1322",
    },
  },
  capex: {
    approval: {
      approve: "#237804",
      reject: "#CF1322",
      revise: "#D48806",
      submit: "#001D66",
      cancel: "#CF1322",
    },
  },
};

const baseThemeConfig: TThemeConfig = {
  components: {
    Menu: {
      itemColor: "#FFF",
      itemSelectedColor: "#FFF",
      itemHoverBg: customThemeColors.primary,
      itemHoverColor: "#FFF",
      itemSelectedBg: customThemeColors.primary,
      fontSize: 14,
      horizontalItemSelectedColor: customThemeColors.primary,
    },
    Layout: {
      headerColor: "#001213",
      headerBg: "#FFF",
    },
  },
  token: {
    colorPrimary: customThemeColors.link,
    colorLink: customThemeColors.link,
  },
  admiral: {
    Sidebar: {
      colorBg: customThemeColors.secondary,
      colorText: "white",
    },
    Page: {
      NavigationAs: ({ path, label }) => <Link href={path}>{label}</Link>,
    },
  },
};

export const globalThemeConfig: TThemeConfig = {
  ...baseThemeConfig,
  admiral: {
    ...baseThemeConfig.admiral,
    DataTable: {
      size: [10, 50, 100, 500, 1000],
    },
  },
};

export const otcThemeConfig: TThemeConfig = {
  ...baseThemeConfig,
  admiral: {
    ...baseThemeConfig.admiral,
    DataTable: {
      size: [10, 20, 50, 100],
    },
  },
};

export const defaultGutter: RowProps["gutter"] = [16, 16];
export const defaultSizeSpace: SpaceProps["size"] = "middle";
export const customIconActionTableStyle: React.CSSProperties = {
  ...iconActionTableStyle,
  fontSize: "16px",
};
export const buttonSaveBySectionStyle: React.CSSProperties = {
  backgroundColor: "#D4B106",
  color: "#FFF",
};
export const defaultDescriptionResponsive: Partial<Record<Breakpoint, number>> =
  {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
    xxl: 2,
  };
